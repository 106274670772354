export interface Courier {
  id: number;
  firstName: string;
  lastName: string;
  familyName: string;
  fullName: string;
  phone: string;
}

export const decodeCourier = (json: any): Courier => ({
  id: json.id,
  firstName: json.first_name,
  lastName: json.last_name,
  familyName: json.family_name,
  fullName: json.full_name,
  phone: json.phone,
});

