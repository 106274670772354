import { ActionType, getType } from 'typesafe-actions';
import { getInitialState, AppState } from "./index";
import * as AllAction from './actionCreators';

type AllActionType = ActionType<typeof AllAction>;

const reducer = (state: AppState = getInitialState(), action: AllActionType): AppState => {
  switch (action.type) {
    case getType(AllAction.setTokenAction): {
      if (action.payload) {
        localStorage.setItem('courier_token', action.payload);
      } else {
        localStorage.removeItem('courier_token');
      }

      return {
        ...state,
        token: action.payload,
      }
    }

    case getType(AllAction.deleteTokenAction): {
      localStorage.removeItem('courier_token');

      return {
        ...state,
        token: undefined,
      }
    }

    case getType(AllAction.setCourier): {
      return {
        ...state,
        courier: action.payload,
      }
    }

    default:
      return state;
  }
}

export default reducer;
