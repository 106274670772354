export interface Product {
  id: number;
  title: string;
  photo: string;
  smallNotes: string;
}

export const decodeProduct = (json: any): Product => ({
  id: json.id,
  title: json.title,
  photo: json.photo,
  smallNotes: json.small_notes,
});
