import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import {
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Button,
  useToast,
} from "@chakra-ui/core";
import { Helmet } from "react-helmet";
import { useApi } from '../../modules/api';
import { useDispatch } from 'react-redux';
import { setTokenAction } from '../../store/actionCreators';

type AnyObject = { [key: string]: any };

const LoginPage: FC = () => {
  const api = useApi();
  const toast = useToast();
  const dispatch = useDispatch();
  const [formId, setFormId] = useState('');
  const [formCode, setFormCode] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState<AnyObject>({});

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setErrors({});

    const newErrors: AnyObject = {};
    if (!formId) {
      newErrors.id = 'Укажите ID';
    }
    if (!formCode || formCode.toString().length !== 6) {
      newErrors.code = 'Код должен быть из 6 цифр';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      const response = await api.post('/authorize', {
        id: formId,
        code: formCode,
      });
      if (response.data.token) {
        dispatch(setTokenAction(response.data.token));

        toast({
          title: "Вы успешно авторизовались",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (e) {
      setLoading(false);
      toast({
        title: 'Произошла ошибка',
        description: e.response?.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const handleChangeId = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormId(value);
  }

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFormCode(value);
  }

  return (
    <div>
      <Helmet>
        <title>Авторизация курьера</title>
      </Helmet>

      <Heading as="h1" size="lg">Вход</Heading>

      <form onSubmit={handleSubmit}>
        <FormControl isInvalid={!!errors.id}>
          <FormLabel htmlFor="id">Ваш ID</FormLabel>
          <Input type="number" id="id" boxSizing="border-box" value={formId} onChange={handleChangeId} />
          {errors.id && (
            <FormErrorMessage>{errors.id}</FormErrorMessage>
          )}
          <FormHelperText>
            Числовой идентификатор
          </FormHelperText>
        </FormControl>
        <FormControl isInvalid={!!errors.code}>
          <FormLabel htmlFor="code">Код из приложения</FormLabel>
          <Input type="number" id="code" boxSizing="border-box" value={formCode} onChange={handleChangeCode} />
          {errors.code && (
            <FormErrorMessage>{errors.code}</FormErrorMessage>
          )}
          <FormHelperText>
            Шестизначный код из приложения
          </FormHelperText>
        </FormControl>

        <Button type="submit" variantColor="orange" variant="solid" isLoading={isLoading}>Войти</Button>
      </form>
    </div>
  );
};

export default LoginPage;
