import React, {ChangeEvent, FC, FormEvent, useRef, useState} from 'react';
import {Helmet} from "react-helmet";
import {Input, Accordion, AccordionItem, AccordionHeader, Box, AccordionPanel, AccordionIcon} from "@chakra-ui/core";
import Header from "../../components/Header/Header";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Spinner, useToast} from "@chakra-ui/core/dist";
import {deleteTokenAction} from "../../store/actionCreators";
import {useApi} from "../../modules/api";
import {AssignmentGroup} from "../../models/assignment-group";
import {useDispatch} from "react-redux";

const AssignmentPage: FC = () => {
  const api = useApi();
  const toast = useToast();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const scannerRef = useRef<any>();
  const [assignError, setAssignError] = useState<string | undefined>(undefined);
  const [assignGroups, setAssignGroups] = useState<AssignmentGroup[] | undefined>();

  const handleSubmitForm = (event: FormEvent) => {
    event.preventDefault();
    handleSearchProduct(query);
  }

  /*const handleScanBarcode = (barcode: string) => {
    console.log('barcode', barcode);
    setQuery(barcode);
    handleSearchProduct(barcode);
  }*/

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setQuery(value);
  }

  const [isLoading, setLoading] = useState(false);

  const handleSearchProduct = async (searchQuery: string) => {
    if (!searchQuery) {
      return;
    }

    setAssignGroups([]);
    setAssignError(undefined);
    setLoading(true);

    try {
      const response = await api.post('/assignment/find', { barcode: searchQuery });
      setAssignGroups(response.data.orders);
      setQuery('');
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(0, 999);
      }
    } catch (e) {
      setQuery('');
      if (e.response?.status === 401) {
        dispatch(deleteTokenAction());
        toast({
          title: 'Сессия истекла',
          description: 'Необходимо снова войти в аккаунт',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setAssignError(e.response?.data?.message || 'Произошла ошибка');
    }
    setLoading(false);
  }

  return (
    <>
      <Helmet>
        <title>Распределение заказов</title>
      </Helmet>

      <Header>
        <div className="mypet-orders-page__title-wrapper">
          <div className="mypet-title">Распределение заказов</div>
        </div>
      </Header>

      <div className="content">
        <form onSubmit={handleSubmitForm} style={{ marginTop: 20 }}>
          <Input
            ref={inputRef}
            type="text"
            placeholder="Штрих-код или артикул"
            autoComplete="off"
            autoFocus
            boxSizing="border-box"
            value={query}
            onChange={handleChangeQuery}
          />
        </form>

        <div style={{ marginTop: 25 }}>
          {isLoading && (
            <Spinner
              thickness="2px"
              speed="0.65s"
            />
          )}
          {!isLoading && (
            <>
              {assignError && (
                <div style={{ margin: '16px -16px 16px -16px' }}>
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle mr={2}>Ошибка!</AlertTitle>
                    <AlertDescription>{assignError}</AlertDescription>
                  </Alert>
                </div>
              )}
              {!assignError && typeof assignGroups !== "undefined" && assignGroups.length > 0 && (
                <>
                  <Accordion>
                    {assignGroups.map(assignGroup => (
                      <AccordionItem key={assignGroup.key}>
                        <AccordionHeader>
                          <Box flex="1" textAlign="left">
                            <div className="mypet-title">
                              {assignGroup.name} {assignGroup.qty}&nbsp;шт.
                            </div>
                          </Box>
                          <AccordionIcon />
                        </AccordionHeader>
                        <AccordionPanel pb={4}>
                          {assignGroup.orders.map(orderGroup => (
                            <div key={orderGroup.id} style={{ marginTop: 5, marginBottom: 15, fontSize: 16 }}>
                              {orderGroup.id} - {orderGroup.qty}&nbsp;шт.
                            </div>
                          ))}
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </>
              )}
              {!assignError && typeof assignGroups !== "undefined" && assignGroups.length === 0 && (
                <div style={{ margin: '16px -16px 16px -16px' }}>
                  <Alert status="success">
                    <AlertIcon />
                    <AlertTitle mr={2}>Товар сегодня не нужен</AlertTitle>
                  </Alert>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AssignmentPage
