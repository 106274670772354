import { createAction } from 'typesafe-actions';
import { Courier } from '../models/courier';

/**
 * Сохраняет токен
 */
export const setTokenAction = createAction('auth/set-token')<string | undefined>();

/**
 * Удаляет токен
 */
export const deleteTokenAction = createAction('auth/delete-token')();

export const setCourier = createAction('auth/set-courier')<Courier | undefined>();
