import React, {ChangeEvent, FC, FormEvent, useRef, useState} from 'react';
import {useApi} from "../../modules/api";
import {Alert, AlertIcon, AlertTitle, AlertDescription, Spinner, Input, useToast, Heading, Box, FormControl, FormLabel, Button} from "@chakra-ui/core";
import {useDispatch} from "react-redux";
import {deleteTokenAction} from "../../store/actionCreators";
import {decodeProduct, Product} from "../../models/product";
import {Helmet} from "react-helmet";
import Header from "../../components/Header/Header";
import {Stack} from "@chakra-ui/core/dist";

interface Props {
  //
}

const WarehouseIncomePage: FC<Props> = () => {
  const api = useApi();
  const toast = useToast();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const [product, setProduct] = useState<Product | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [assignError, setAssignError] = useState<string | undefined>(undefined);

  const handleSubmitForm = (event: FormEvent) => {
    event.preventDefault();
    handleSearchProduct(query);
  }

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target;
    setQuery(value);
  }

  const [isLoading, setLoading] = useState(false);

  const handleSearchProduct = async (searchQuery: string) => {
    if (!searchQuery) {
      return;
    }

    setProduct(undefined);
    setAssignError(undefined);
    setLoading(true);

    try {
      const response = await api.post('/product/find-one', { query: searchQuery });
      if (response.data.product) {
        setProduct(decodeProduct(response.data.product));
      } else {
        setAssignError('Товар не найден');
      }
      setQuery('');
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(0, 999);
      }
    } catch (e) {
      setQuery('');
      if (e.response?.status === 401) {
        dispatch(deleteTokenAction());
        toast({
          title: 'Сессия истекла',
          description: 'Необходимо снова войти в аккаунт',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setAssignError(e.response?.data?.message || 'Произошла ошибка');
    }
    setLoading(false);
  }

  const [isIncoming, setIsIncoming] = useState(false);


  const handleIncome = async (event: FormEvent) => {
    event.preventDefault();

    setIsIncoming(true);

    try {
      await api.post('/warehouse/income', { product_id: product?.id, qty });
      setProduct(undefined);
      toast({
        title: 'Товар успешно оприходован',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(0, 999);
      }
    } catch (e) {
      if (e.response?.status === 401) {
        dispatch(deleteTokenAction());
        toast({
          title: 'Сессия истекла',
          description: 'Необходимо снова войти в аккаунт',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      setAssignError(e.response?.data?.message || 'Произошла ошибка');
    }
    setIsIncoming(false);
  }

  const [qty, setQty] = useState('1');

  const handleChangeQty = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQty(value);
  }


  return (
    <>
      <Helmet>
        <title>Приход на склад</title>
      </Helmet>

      <Header>
        <div className="mypet-orders-page__title-wrapper">
          <div className="mypet-title">Приход на склад</div>
        </div>
      </Header>

      <div className="content">
        <form onSubmit={handleSubmitForm} style={{ marginTop: 20 }}>
          <Input
            ref={inputRef}
            type="text"
            placeholder="Штрих-код или артикул"
            autoComplete="off"
            autoFocus
            boxSizing="border-box"
            value={query}
            onChange={handleChangeQuery}
          />
        </form>

        <div style={{ marginTop: 25 }}>
          {isLoading && (
            <Spinner
              thickness="2px"
              speed="0.65s"
            />
          )}
          {!isLoading && (
            <>
              {assignError && (
                <div style={{ margin: '16px -16px 16px -16px' }}>
                  <Alert status="error">
                    <AlertIcon />
                    <AlertTitle mr={2}>Ошибка!</AlertTitle>
                    <AlertDescription>{assignError}</AlertDescription>
                  </Alert>
                </div>
              )}
              {!assignError && typeof product !== "undefined" && (
                <form onSubmit={handleIncome}>
                  <div>
                    <Heading as="h2" size="sm">{product.title}</Heading>

                    <Stack spacing={16} direction="column">
                      <Stack spacing={16}>
                        <FormControl>
                          <FormLabel htmlFor="qty">Количество</FormLabel>
                          <Input type="number" id="qty" boxSizing="border-box" value={qty} onChange={handleChangeQty} />
                        </FormControl>
                      </Stack>
                      <Button type="submit" variantColor="orange" variant="solid" isLoading={isIncoming}>Оприходовать</Button>
                    </Stack>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default WarehouseIncomePage
