import React, { FC, useState } from 'react';
import { Order } from '../../models/order';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/core";
import { useApi } from '../../modules/api';
import { deleteTokenAction } from '../../store/actionCreators';
import { useDispatch } from 'react-redux';

interface Props {
  order: Order;
  isOpen: boolean;
  handleClose: () => void;
  handleDone: () => void;
}

const OrderCancelModal: FC<Props> = ({ isOpen, order, handleClose, handleDone }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const toast = useToast();

  const [isSaving, setSaving] = useState(false);

  const handleCancelOrder = async () => {
    setSaving(true);
    try {
      await api.post(`/orders/${order.id}/cancel`);
      setSaving(false);
      toast({
        title: 'Заказ отменен',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      handleDone();
    } catch (e) {
      setSaving(false);
      if (e.response?.status === 401) {
        dispatch(deleteTokenAction());
        toast({
          title: 'Сессия истекла',
          description: 'Необходимо снова войти в аккаунт',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Отмена заказа</ModalHeader>
        <ModalCloseButton isDisabled={isSaving} />
        <ModalBody>
          <p style={{ fontSize: 18 }}>Вы действительно хотите отменить заказ?</p>
        </ModalBody>

        <ModalFooter>
          <Button variantColor="red" mr={3} isLoading={isSaving} onClick={handleCancelOrder}>
            Отменить заказ
          </Button>
          <Button variant="ghost" isLoading={isSaving} onClick={handleClose}>Закрыть</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderCancelModal;
