export interface Order {
  id: number;
  statusId: number;
  statusName: string;
  clientName: string;
  clientPhone: string;
  timeFrom: string;
  timeTo: string;
  address: string;
  addressNotes?: string;
  notes?: string;
  totalAmount: number;
  oweAmount: number;
}

export const decodeOrder = (json: any): Order => ({
  id: json.id,
  statusId: json.status_id,
  statusName: json.status_name,
  clientName: json.client_name,
  clientPhone: json.client_phone,
  timeFrom: json.time_from,
  timeTo: json.time_to,
  address: json.address,
  addressNotes: json.address_notes,
  totalAmount: json.total_amount,
  oweAmount: json.owe_amount,
});
