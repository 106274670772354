import React, {FC, ReactNode, useMemo, useRef, useState} from 'react';
import './Header.css';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { deleteTokenAction } from '../../store/actionCreators';
import { courierSelector } from '../../store/selectors';
import {Link, useLocation} from "react-router-dom";

interface Props {
  left?: ReactNode;
}

const Header: FC<Props> = ({ left, children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const sectionName = useMemo(() => {
    if (location.pathname === '/') {
      return 'orders';
    }

    if (location.pathname === '/assignment') {
      return 'assignment';
    }

    if (location.pathname === '/warehouse/income') {
      return 'warehouse:income';
    }
  }, [location.pathname]);

  const courier = useSelector(courierSelector);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const onCloseLogout = () => setIsLogoutOpen(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleLogout = () => {
    setIsLogoutOpen(true);
  };

  const makeLogout = () => {
    setIsLogoutOpen(false)
    dispatch(deleteTokenAction());
  }

  return (
    <div className="mypet-header">
      {left && (
        <div className="mypet-header__left">
          {left}
        </div>
      )}

      {children}

      <AlertDialog
        isOpen={isLogoutOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseLogout}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Выход
          </AlertDialogHeader>

          <AlertDialogBody>
            Вы действительно хотите выйти?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseLogout}>
              Не выходить
            </Button>
            <Button variantColor="red" onClick={makeLogout} ml={3}>
              Выйти
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <div className="mypet-header__right">
        <IconButton icon="info" aria-label="Меню" onClick={onOpen} />
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{courier ? courier.fullName : 'Профиль'}</DrawerHeader>

            <DrawerBody>
              <Link to="/" className={`mypet-drawer-link ${sectionName === 'orders' ? 'mypet-drawer-link--active' : ''}`}>
                Заказы
              </Link>
              <Link to="/assignment" className={`mypet-drawer-link ${sectionName === 'assignment' ? 'mypet-drawer-link--active' : ''}`}>
                Распределение
              </Link>
              <Link to="/warehouse/income" className={`mypet-drawer-link ${sectionName === 'warehouse:income' ? 'mypet-drawer-link--active' : ''}`}>
                Приход товара
              </Link>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Закрыть
              </Button>
              <Button variantColor="red" onClick={handleLogout}>Выйти</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </div>
    </div>
  );
};

export default Header;
