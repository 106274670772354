import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducer';
import { Courier } from '../models/courier';

export interface AppState {
  token?: string;
  courier?: Courier;
}

export const getInitialState = (): AppState => ({
  token: localStorage.getItem('courier_token') || undefined,
});

export const initializeStore = (state: AppState = getInitialState()) => {
  const store: Store<AppState> =
    process.env.NODE_ENV !== 'production'
      ? createStore(rootReducer, state, composeWithDevTools(applyMiddleware()))
      : createStore(rootReducer, state);

  return store;
};

export const store = initializeStore();
