import React, { FC, ReactNode } from 'react';
import './Price.css';

export type PriceType = 'exact' | 'approx' | 'from';

interface Props {
  price: number;
  space?: ReactNode;
  type?: PriceType;
  variant?: 'red';
  onlyAbsolute?: boolean;
}

const Price: FC<Props> = ({
  space = <>&nbsp;</>, price, onlyAbsolute, type = 'exact', variant,
}) => (
  <span className="mypet-price">
    {type === 'from' && 'от '}
    {type === 'approx' && '≈ '}
    {price}
    {space}
    <span className="mypet-rouble">руб.</span>
  </span>
);

export default Price;
