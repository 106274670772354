import React, { FC } from 'react';
import './OrderStatusBadge.css';

interface Props {
  statusId: number;
  statusName: string;
}

const OrderStatusBadge: FC<Props> = ({ statusId, statusName }) => {
  return (
    <div className={`mypet-order-status-badge mypet-order-status-badge--size-medium mypet-order-status-badge--status-${statusId}`}>
      {statusName}
    </div>
  );
};

export default OrderStatusBadge;
