import React, { FC, useEffect } from 'react';
import { RouteProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedSelector } from '../../store/selectors';
import { Route, Redirect } from 'react-router-dom';
import { useApi } from '../../modules/api';
import { decodeOrder } from '../../models/order';
import { deleteTokenAction, setCourier } from '../../store/actionCreators';
import { decodeCourier } from '../../models/courier';
import { useToast } from '@chakra-ui/core/dist';

interface Props extends RouteProps {
  redirectTo?: string;
}

const PrivateRoute: FC<Props> = ({ children, redirectTo = '/login', ...rest }) => {
  const api = useApi();
  const toast = useToast();
  const dispatch = useDispatch();
  const isLogged = useSelector(isLoggedSelector);

  const fetchCourierInfo = async () => {
    try {
      const response = await api.get('/me');
      const courier = decodeCourier(response.data.courier);
      dispatch(setCourier(courier));
    } catch (e) {
      if (e.response?.status === 401) {
        dispatch(deleteTokenAction());
        toast({
          title: 'Сессия истекла',
          description: 'Необходимо снова войти в аккаунт',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
  }

  useEffect(() => {
    if (isLogged) {
      fetchCourierInfo();
    }
  }, [isLogged]);

  return (
    <Route {...rest} render={() => (
      !isLogged ?
        <Redirect to={redirectTo} />
        : children
    )} />
  );
};

export default PrivateRoute;
