import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import { Button, IconButton, useToast, useClipboard } from '@chakra-ui/core/dist';
import { decodeOrder, Order } from '../../models/order';
import OrderCellSkeleton from '../../components/Skeletons/OrderCellSkeleton';
import { useApi } from '../../modules/api';
import { useDispatch } from 'react-redux';
import { deleteTokenAction } from '../../store/actionCreators';
import './OrderPage.css';
import OrderStatusBadge from '../../components/OrderStatusBadge/OrderStatusBadge';
import Price from '../../components/Price/Price';
import OrderSuccessModal from '../../components/OrderSuccessModal/OrderSuccessModal';
import OrderCancelModal from '../../components/OrderCancelModal/OrderCancelModal';

interface RouteProps {
  orderId?: string;
}

const OrderPage: FC = () => {
  const api = useApi();
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderId } = useParams<RouteProps>();

  const handleBack = () => {
    history.push('/');
  };

  const [isLoading, setLoading] = useState(false);
  const [isDoneModalOpened, setDoneModalOpened] = useState(false);
  const [isCancelModalOpened, setCancelModalOpened] = useState(false);
  const [order, setOrder] = useState<Order | undefined>();
  const { onCopy, hasCopied } = useClipboard(order ? order.address : undefined);
  const [isMakeDone, setMakeDone] = useState(false);

  const handleOpenDoneModal = () => {
    setDoneModalOpened(true);
  }

  const handleCloseDoneModal = () => {
    setDoneModalOpened(false);
  }

  const handleChangedStatus = () => {
    setDoneModalOpened(false);
    setCancelModalOpened(false);
    loadOrder();
  }

  const handleOpenCancelModal = () => {
    setCancelModalOpened(true);
  }

  const handleCloseCancelModal = () => {
    setCancelModalOpened(false);
  }

  const loadOrder = async () => {
    setLoading(true);

    try {
      const response = await api.get(`/orders/${orderId}`);
      const newOrder = decodeOrder(response.data.order);
      setOrder(newOrder);
    } catch (e) {
      if (e.response?.status === 401) {
        dispatch(deleteTokenAction());
        toast({
          title: 'Сессия истекла',
          description: 'Необходимо снова войти в аккаунт',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadOrder();
  }, []);

  const handlePhone = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (order) {
      window.location.href = `tel:${order.clientPhone}`;
    }
  };

  useEffect(() => {
    if (hasCopied === true) {
      toast({
        title: 'Адрес скопирован',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [hasCopied]);

  return (
    <>
      <Helmet>
        <title>Заказ{orderId ? ` №${orderId}` : ''}</title>
      </Helmet>
      <Header left={<IconButton aria-label="Назад" icon="arrow-back" onClick={handleBack} />}>
        <div className="mypet-title">Заказ{orderId ? ` №${orderId}` : ''}</div>
      </Header>
      <div className="content">
        {order && (
          <>
            <OrderSuccessModal order={order} isOpen={isDoneModalOpened} handleClose={handleCloseDoneModal} handleDone={handleChangedStatus} />
            <OrderCancelModal order={order} isOpen={isCancelModalOpened} handleClose={handleCloseCancelModal} handleDone={handleChangedStatus} />
          </>
        )}
        {isLoading && (
          <>
            <OrderCellSkeleton />
            <OrderCellSkeleton />
            <OrderCellSkeleton />
            <OrderCellSkeleton />
            <OrderCellSkeleton />
          </>
        )}
        {!isLoading && order && (
          <>
            <div className="mypet-order-page__section">
              <OrderStatusBadge statusId={order.statusId} statusName={order.statusName} />
            </div>
            <div className="mypet-order-page__section">
              <h3>Клиент</h3>
              <div className="mypet-order-page__btn-block">
                <div className="mypet-order-page__btn-content">
                  <div>{order.clientName}</div>
                  <div className="mypet-order-page__phone">{order.clientPhone}</div>
                </div>
                <div className="mypet-order-page__btn">
                  <IconButton icon="phone" aria-label="Позвонить" onClick={handlePhone} />
                </div>
              </div>
            </div>
            <div className="mypet-order-page__section">
              <h3>Доставка</h3>
              <div className="mypet-order-page__delivery-time">Время: <strong>с {order.timeFrom} до {order.timeTo}</strong></div>
              <div className="mypet-order-page__btn-block">
                <div className="mypet-order-page__btn-content">
                  <div className="mypet-order-page__address">{order.address}</div>
                  {order.addressNotes && (
                    <div className="mypet-order-page__address-notes">{order.addressNotes}</div>
                  )}
                </div>
                <div className="mypet-order-page__btn">
                  <IconButton icon="copy" aria-label="Скопировать адрес" onClick={onCopy} />
                </div>
              </div>
            </div>

            <div className="mypet-order-page__section">
              <h3>Оплата</h3>
              <div className="mypet-order-page__prop-title">
                Сумма заказа
              </div>
              <div className="mypet-order-page__prop-value">
                <Price price={order.totalAmount} />
              </div>
              <div className="mypet-order-page__owe-amount">
                К оплате <Price price={order.oweAmount} />
              </div>
            </div>

            <div className="mypet-order-page__section">
              <h3>Действия</h3>
              {order.statusId !== 4 && (
                <div className="mypet-order-page__main-button">
                  <Button variantColor="green" variant="solid" isLoading={isMakeDone} onClick={handleOpenDoneModal} size="lg" width="100%">Выполнить заказ</Button>
                </div>
              )}
              {order.statusId !== 6 && (
                <div className="mypet-order-page__main-button">
                  <Button variantColor="red" variant="solid" isLoading={isMakeDone} onClick={handleOpenCancelModal} size="lg" width="100%">Отменить заказ</Button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderPage;
