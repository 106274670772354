import React, { FC, useEffect, useMemo, useState } from 'react';
import Header from '../../components/Header/Header';
import {
  Text,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Progress,
  useToast,
} from "@chakra-ui/core/dist";
import { Helmet } from "react-helmet";
import OrderCellSkeleton from '../../components/Skeletons/OrderCellSkeleton';
import { useApi } from '../../modules/api';
import { decodeOrder, Order } from '../../models/order';
import OrderCell from '../../components/OrderCell/OrderCell';
import { deleteTokenAction } from '../../store/actionCreators';
import { useDispatch } from 'react-redux';
import './OrdersPage.css';
import Price from '../../components/Price/Price';

const OrdersPage: FC = () => {
  const api = useApi();
  const toast = useToast();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [ordersAmount, setOrdersAmount] = useState<{ cash: number; card: number; }>({ cash: 0, card: 0 });
  const [orders, setOrders] = useState<Order[]>([]);

  const inWorkOrders = useMemo(() => {
    return orders.filter(order => [4, 6].indexOf(order.statusId) < 0);
  }, [orders]);

  const passedOrders = useMemo(() => {
    return orders.filter(order => [4, 6].indexOf(order.statusId) >= 0);
  }, [orders]);

  const loadOrders = async () => {
    setLoading(true);

    try {
      const response = await api.get('/orders');
      const newOrders = response.data.orders.map(decodeOrder);
      setOrders(newOrders);
      setOrdersAmount(response.data.amounts);
    } catch (e) {
      if (e.response?.status === 401) {
        dispatch(deleteTokenAction());
        toast({
          title: 'Сессия истекла',
          description: 'Необходимо снова войти в аккаунт',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadOrders();
  }, []);

  return (
    <>
      <Helmet>
        <title>Заказы</title>
      </Helmet>

      <Header>
        <div className="mypet-orders-page__title-wrapper">
          <div className="mypet-title">Заказы</div>
          {orders.length > 0 && (
            <div className="mypet-orders-page__title-progress">
              <div className="mypet-orders-page__title-progress-label">{passedOrders.length}/{orders.length}</div>
              <Progress color="green" size="sm" value={passedOrders.length / orders.length * 100} width="100%" borderRadius={4} />
            </div>
          )}
        </div>
      </Header>
      <div className="content">
        {!isLoading && orders.length > 0 && (
          <>
            {inWorkOrders.length > 0 && (
              <>
                <div className="mypet-orders-page__section-block">
                  <Text fontSize="lg" fontWeight={800} margin={0}>
                    В работе
                    <Badge ml="2" fontSize="lg" variantColor="purple">
                      {inWorkOrders.length}
                    </Badge>
                  </Text>
                </div>
                {inWorkOrders.map(order => (
                  <OrderCell key={order.id} order={order} />
                ))}
              </>
            )}
            {inWorkOrders.length === 0 && (
              <>
                <div style={{ margin: '16px -16px 16px -16px' }}>
                  <Alert status="success">
                    <AlertIcon />
                    <AlertTitle mr={2}>Отлично!</AlertTitle>
                    <AlertDescription>Все заказы выполнены.</AlertDescription>
                  </Alert>
                </div>
                <div style={{ marginBottom: 24 }}>
                  <div className="mypet-orders-page__section-block">
                    <Text fontSize="lg" fontWeight={800} margin={0}>
                      Отчет за день
                    </Text>
                  </div>

                  <StatGroup>
                    <Stat>
                      <StatLabel>Наличными</StatLabel>
                      <StatNumber>
                        <Price price={ordersAmount.cash} />
                      </StatNumber>
                    </Stat>

                    <Stat>
                      <StatLabel>Картой</StatLabel>
                      <StatNumber>
                        <Price price={ordersAmount.card} />
                      </StatNumber>
                    </Stat>
                  </StatGroup>
                </div>
              </>
            )}
            {passedOrders.length > 0 && (
              <>
                <div className="mypet-orders-page__section-block">
                  <Text fontSize="lg" fontWeight={800} margin={0}>
                    Обработанные
                    <Badge ml="2" fontSize="lg" variantColor="green">
                      {passedOrders.length}
                    </Badge>
                  </Text>
                </div>
                {passedOrders.map(order => (
                  <OrderCell key={order.id} order={order} />
                ))}
              </>
            )}
          </>
        )}
        {!isLoading && orders.length === 0 && (
          <div style={{ margin: '16px -16px 16px -16px' }}>
            <Alert status="info">
              <AlertIcon />
              <AlertTitle mr={2}>На сегодня у вас нет заказов</AlertTitle>
            </Alert>
          </div>
        )}
        {isLoading && (
          <>
            <OrderCellSkeleton />
            <OrderCellSkeleton />
            <OrderCellSkeleton />
            <OrderCellSkeleton />
            <OrderCellSkeleton />
          </>
        )}
      </div>
    </>
  );
};

export default OrdersPage;
