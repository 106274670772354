import axios, { AxiosRequestConfig } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from '../store/selectors';
import { useMemo } from 'react';
import { Dispatch } from 'redux';

export const useApi = () => {
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  return useMemo(() => (new Api(dispatch, token)), [token]);
}

export class Api {
  protected token?: string;
  protected dispatch: Dispatch;

  constructor(dispatch: Dispatch, token?: string) {
    this.token = token;
    this.dispatch = dispatch;
  }

  protected processUrl = (url: string): string => {
    return url;
  };

  protected createInstance() {
    const headers: any = {
      'Content-Type': 'application/json',
    };
    if (this.token) {
      headers['X-Courier-Token'] = this.token;
    }

    const baseURL = process.env.REACT_APP_API_URL;

    return axios.create({
      baseURL,
      headers,
    });
  }

  get(url: string, config?: AxiosRequestConfig) {
    return this.createInstance().get(this.processUrl(url), config);
  }

  post(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.createInstance().post(this.processUrl(url), data, config);
  }

  put(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.createInstance().put(this.processUrl(url), data, config);
  }

  delete(url: string, config?: AxiosRequestConfig) {
    return this.createInstance().delete(this.processUrl(url), config);
  }
}
