import React, { FC } from 'react';
import { ThemeProvider } from '@chakra-ui/core';
import { Provider } from 'react-redux';
import { store } from '../../store';
import theme from './theme';
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import './App.css';
import OrdersPage from '../../pages/OrdersPage/OrdersPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import PrivateRoute from '../Router/PrivateRoute';
import PublicRoute from '../Router/PublicRoute';
import OrderPage from '../../pages/OrderPage/OrderPage';
import AssignmentPage from "../../pages/AssignmentPage/AssignmentPage";
import WarehouseIncomePage from "../../pages/WarehouseIncomePage/WarehouseIncomePage";

const App: FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <PrivateRoute path="/" exact>
              <OrdersPage />
            </PrivateRoute>
            <PrivateRoute path="/order/:orderId" exact>
              <OrderPage />
            </PrivateRoute>
            <PrivateRoute path="/assignment" exact>
              <AssignmentPage />
            </PrivateRoute>
            <PrivateRoute path="/warehouse/income" exact>
              <WarehouseIncomePage />
            </PrivateRoute>
            <PublicRoute path="/login" exact>
              <LoginPage />
            </PublicRoute>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
