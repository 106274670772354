import { AppState } from './index';
import { createSelector } from 'redux-views';

const stateSelector = (state: AppState) => state;

export const tokenSelector = createSelector(
  [stateSelector],
  (state) => state.token
);

export const isLoggedSelector = createSelector(
  [tokenSelector],
  (token) => !!token,
);

export const courierSelector = createSelector(
  [stateSelector],
  (state) => state.courier
);
