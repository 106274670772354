import React, { FC, MouseEvent } from 'react';
import { Order } from '../../models/order';
import { IconButton } from '@chakra-ui/core/dist';
import './OrderCell.css';
import { useHistory } from 'react-router-dom';

interface Props {
  order: Order;
}

const OrderCell: FC<Props> = ({ order }) => {
  const history = useHistory();

  const handlePhone = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = `tel:${order.clientPhone}`;
  };

  const handleClick = () => {
    history.push(`/order/${order.id}`);
  }

  return (
    <div className={`mypet-order-cell mypet-order-cell--status-${order.statusId}`} onClick={handleClick}>
      <div className="mypet-order-cell__main-wrapper ">
        <div className="mypet-order-cell__content">
          <div className="mypet-order-cell__first-line">
            <div className="mypet-order-cell__number">{order.id}</div>
            <div className="mypet-order-cell__time">{order.timeFrom}&ndash;{order.timeTo}</div>
          </div>
          <div className="mypet-order-cell__client-name">
            {order.clientName}
          </div>
        </div>
        <div className="mypet-order-cell__phone">
          <IconButton icon="phone" aria-label="Позвонить" onClick={handlePhone} />
        </div>
      </div>
      <div className="mypet-order-cell__address">
        {order.address}
      </div>
    </div>
  );
};

export default OrderCell;
