import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { Order } from '../../models/order';
import './OrderSuccessModal.css';
import {
  Alert,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/core";
import Price from '../Price/Price';
import { useApi } from '../../modules/api';
import { deleteTokenAction } from '../../store/actionCreators';
import { useDispatch } from 'react-redux';

interface Props {
  order: Order;
  isOpen: boolean;
  handleClose: () => void;
  handleDone: () => void;
}

const OrderSuccessModal: FC<Props> = ({ isOpen, order, handleClose, handleDone }) => {
  const api = useApi();
  const dispatch = useDispatch();
  const toast = useToast();
  const [paymentType, setPaymentType] = React.useState<string | undefined>();
  const [cashAmount, setCacheAmount] = React.useState<string | undefined>();
  const canMarkAsSuccess = useMemo(() => {
    if (order.oweAmount === 0) {
      return true;
    }
    return !!paymentType;
  }, [paymentType, order]);
  const handleChangeCashAmount = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCacheAmount(value);
  }

  const changeAmount = useMemo(() => {
    if (!cashAmount) {
      return undefined;
    }
    const clientCashInteger = Number.parseInt(cashAmount, 10);
    if (Number.isNaN(clientCashInteger)) {
      return undefined;
    }

    return clientCashInteger - order.oweAmount;
  }, [cashAmount, order]);

  const [isSaving, setSaving] = useState(false);

  const handleDoneOrder = async () => {
    setSaving(true);
    try {
      await api.post(`/orders/${order.id}/done`, { payment_type: paymentType });
      setSaving(false);
      toast({
        title: 'Заказ успешно выполнен',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      handleDone();
    } catch (e) {
      setSaving(false);
      if (e.response?.status === 401) {
        dispatch(deleteTokenAction());
        toast({
          title: 'Сессия истекла',
          description: 'Необходимо снова войти в аккаунт',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Выполнение заказа</ModalHeader>
        <ModalCloseButton isDisabled={isSaving} />
        <ModalBody>
          {order.oweAmount > 0 && (
            <>
              <div className="mypet-order-page__owe-amount">
                К оплате <Price price={order.oweAmount} />
              </div>

              <h4>Выберите способ оплаты</h4>

              <div className="mypet-order-success-modal__buttons">
                <div className="mypet-order-success-modal__button">
                  <Button variantColor="blue" variant={paymentType === 'cash' ? 'solid' : 'outline'} width="100%" onClick={() => setPaymentType('cash')}>
                    Наличными
                  </Button>
                </div>
                <div className="mypet-order-success-modal__button">
                  <Button variantColor="blue" variant={paymentType === 'card' ? 'solid' : 'outline'} width="100%" onClick={() => setPaymentType('card')}>
                    Картой
                  </Button>
                </div>
              </div>

              {paymentType === 'cash' && (
                <>
                  <Alert status="info">
                    Возьмите у клиента
                    <div>
                      <strong>&nbsp;<Price price={order.oweAmount} /></strong>
                    </div>
                  </Alert>

                  <FormControl isInvalid={false} mt={4}>
                    <FormLabel htmlFor="cash_amount">Сумма от клиента</FormLabel>
                    <Input type="number" id="cash_amount" boxSizing="border-box" value={cashAmount} onChange={handleChangeCashAmount} />
                    {typeof changeAmount !== 'undefined' && (
                      <div style={{ marginTop: 16 }}>
                        Сдача: <strong><Price price={changeAmount} /></strong>
                      </div>
                    )}
                  </FormControl>
                </>
              )}

              {paymentType === 'card' && (
                <>
                  <Alert status="info">
                    Проведите на терминале оплату на
                    <div>
                      <strong><Price price={order.oweAmount} /></strong>
                    </div>
                  </Alert>
                </>
              )}
            </>
          )}
          {order.oweAmount === 0 && (
            <>
              Заказ уже оплачен
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variantColor="green" mr={3} isDisabled={!canMarkAsSuccess} isLoading={isSaving} onClick={handleDoneOrder}>
            Выполнить заказ
          </Button>
          <Button variant="ghost" isLoading={isSaving} onClick={handleClose}>Закрыть</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderSuccessModal;
