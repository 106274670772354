import React, { FC } from 'react';
import { Skeleton } from "@chakra-ui/core";
import './OrderCellSkeleton.css';

const OrderCellSkeleton: FC = () => {
  return (
    <div className="order-cell__skeleton">
      <Skeleton height="20px" my="10px" width="100px" />
      <Skeleton height="20px" my="10px" width="100px" />
      <Skeleton height="20px" my="10px" />
    </div>
  );
};

export default OrderCellSkeleton;
