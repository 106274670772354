import React, { FC } from 'react';
import { RouteProps } from 'react-router';
import { useSelector } from 'react-redux';
import { isLoggedSelector } from '../../store/selectors';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute: FC<RouteProps> = ({children, ...rest}) => {
  const isLogged = useSelector(isLoggedSelector);
  return (
    <Route {...rest} render={props => (
      isLogged ?
        <Redirect to="/" />
        : children
    )} />
  );
};

export default PublicRoute;
